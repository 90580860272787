import { base64 } from './helperService';

const authService = {
  setUser() {
    const _tempData = {};
    return _tempData;
  },
  removeUser() {
    localStorage.removeItem('GetUgAdminLogin');
    return null;
  },
  user() {
    const _tempData = localStorage.getItem('GetUgAdminLogin');

    if (!_tempData) return null;
    const decodedData = base64.decode(_tempData);
    const user = JSON.parse(decodedData);
    return {
      id: user?.id,
      userName: user?.userName,
      email: user?.email,
      token: user?.token,
    };
  },
};

export { authService };
