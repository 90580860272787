import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import UserLayOut from './layouts/userLayout';
import AdminLayout from './layouts/adminLayout';
import { PageLoading } from './providers/SkeletonProvider';
import { selectUser, setLastPage } from './features/userSlice';
import { AdminAxiosProvider } from './providers/AdminAxiosProvider';

const HomePage = lazy(() => import('./pages/HomePage'));
const ProductPage = lazy(() => import('./pages/ProductPage'));
const ProfilePage = lazy(() => import('./pages/Profile'));
const ChatWindow = lazy(() => import('./pages/ChatWindow'));
const FavoritePage = lazy(() => import('./pages/Favorite'));
const Page404 = lazy(() => import('./pages/Page404'));
const MyPost = lazy(() => import('./pages/MyPost'));
const SearchPage = lazy(() => import('./pages/SearchPage'));
const SellPage = lazy(() => import('./pages/SellPage'));
const CategoryPage = lazy(() => import('./pages/Category'));
const AdminLoginPage = lazy(() => import('./pages/AdminLogin'));
const DashBoardPage = lazy(() => import('./pages/Dashboard'));
const UserPage = lazy(() => import('./pages/UserPage'));
const AdminCategory = lazy(() => import('./pages/AdminCategory'));
const AdminProduct = lazy(() => import('./pages/AdminProduct'));
const Verifyuser = lazy(() => import('./sections/auth/verifyUser/VerifyUser'));
// const AdminPromotionProduct = lazy(() => import('./pages/AdminPromotionProduct'));
// const PromotionPayment = lazy(() => import('./pages/PromotionPayment'));
const UserNotifications = lazy(() => import('./pages/UserNotifications'));
const AdminNotifications = lazy(() => import('./pages/AdminNotifications'));
const AdminSlider = lazy(() => import('./pages/AdminSlider'));
const Enquiry = lazy(() => import('./pages/Enquiry'));
const AdminEnquiry = lazy(() => import('./pages/AdminEnquiry'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));

export default function Router() {
  const auth = useSelector(selectUser);
  const location = useLocation();
  const dispatch = useDispatch();
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    // Store the last page URL whenever the location changes
    dispatch(setLastPage(location.pathname));
  }, [location]);

  useEffect(() => {
    if (auth) {
      setUserLoggedIn(true);
    } else {
      setUserLoggedIn(false);
    }
  }, [auth]);
  const routes = useRoutes([
    {
      path: '',
      element: <UserLayOut />,
      children: [
        {
          path: '/',
          element: (
            <Suspense fallback={<PageLoading />}>
              {userLoggedIn ? <HomePage /> : <Navigate to="/home" replace />}
            </Suspense>
          ),
        },
        {
          path: '/home',
          element: (
            <Suspense fallback={<PageLoading />}>
              <HomePage />
            </Suspense>
          ),
        },
        {
          path: '/privacy-policy',
          element: (
            <Suspense fallback={<PageLoading />}>
              <PrivacyPolicy />
            </Suspense>
          ),
        },
        {
          path: '/product/:id',
          element: (
            <Suspense fallback={<PageLoading />}>
              <ProductPage />
            </Suspense>
          ),
        },
        // {
        //   path: '/promotion/:id',
        //   element: (
        //     <>
        //       {userLoggedIn ? (
        //         <Suspense fallback={<PageLoading />}>
        //           <PromotionPayment />
        //         </Suspense>
        //       ) : (
        //         <Suspense fallback={<PageLoading />}>
        //           <HomePage />
        //         </Suspense>
        //       )}
        //     </>
        //   ),
        // },
        {
          path: '/notifications',
          element: (
            <>
              {userLoggedIn ? (
                <Suspense fallback={<PageLoading />}>
                  <UserNotifications />
                </Suspense>
              ) : (
                <Suspense fallback={<PageLoading />}>
                  <HomePage />
                </Suspense>
              )}
            </>
          ),
        },
        {
          path: '/ProfilePage',
          element: (
            <>
              {userLoggedIn ? (
                <Suspense fallback={<PageLoading />}>
                  <ProfilePage />
                </Suspense>
              ) : (
                <Suspense fallback={<PageLoading />}>
                  <HomePage />
                </Suspense>
              )}
            </>
          ),
        },
        {
          path: '/chat',
          element: (
            <>
              {userLoggedIn ? (
                <Suspense fallback={<PageLoading />}>
                  <ChatWindow />
                </Suspense>
              ) : (
                <Suspense fallback={<PageLoading />}>
                  <HomePage />
                </Suspense>
              )}
            </>
          ),
        },
        {
          path: '/favorite',
          element: (
            <>
              {userLoggedIn ? (
                <Suspense fallback={<PageLoading />}>
                  <FavoritePage />
                </Suspense>
              ) : (
                <Suspense fallback={<PageLoading />}>
                  <HomePage />
                </Suspense>
              )}
            </>
          ),
        },
        {
          path: '/myPost',
          element: (
            <>
              {userLoggedIn ? (
                <Suspense fallback={<PageLoading />}>
                  <MyPost />
                </Suspense>
              ) : (
                <Suspense fallback={<PageLoading />}>
                  <HomePage />
                </Suspense>
              )}
            </>
          ),
        },
        {
          path: '/search/:query',
          element: (
            <Suspense fallback={<PageLoading />}>
              <SearchPage />
            </Suspense>
          ),
        },
        {
          path: '/post',
          element: (
            <>
              {userLoggedIn ? (
                <Suspense fallback={<PageLoading />}>
                  <SellPage />
                </Suspense>
              ) : (
                <Suspense fallback={<PageLoading />}>
                  <HomePage />
                </Suspense>
              )}
            </>
          ),
        },
        {
          path: '/category',
          element: (
            <Suspense fallback={<PageLoading />}>
              <CategoryPage />
            </Suspense>
          ),
        },
        {
          path: '/verifyuser',
          element: (
            <Suspense fallback={<PageLoading />}>
              <Verifyuser />
            </Suspense>
          ),
        },
        {
          path: '/help',
          element: (
            <Suspense fallback={<PageLoading />}>
              <Enquiry />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: '/auth',
      element: <AdminLoginPage />,
    },
    {
      path: 'admin',
      element: (
        <AdminAxiosProvider>
          <AdminLayout />
        </AdminAxiosProvider>
      ),
      children: [
        { element: <Navigate to="/dashboard" /> },
        {
          path: 'dashboard',
          element: (
            <Suspense fallback={<PageLoading />}>
              <DashBoardPage />
            </Suspense>
          ),
        },
        {
          path: 'user',
          element: (
            <Suspense fallback={<PageLoading />}>
              <UserPage />
            </Suspense>
          ),
        },
        {
          path: 'category',
          element: (
            <Suspense fallback={<PageLoading />}>
              <AdminCategory />
            </Suspense>
          ),
        },
        {
          path: 'product',
          element: (
            <Suspense fallback={<PageLoading />}>
              <AdminProduct />
            </Suspense>
          ),
        },
        // {
        //   path: 'promote-product',
        //   element: (
        //     <Suspense fallback={<PageLoading />}>
        //       <AdminPromotionProduct />
        //     </Suspense>
        //   ),
        // },
        {
          path: 'notifications',
          element: (
            <Suspense fallback={<PageLoading />}>
              <AdminNotifications />
            </Suspense>
          ),
        },
        {
          path: 'slider',
          element: (
            <Suspense fallback={<PageLoading />}>
              <AdminSlider />
            </Suspense>
          ),
        },
        {
          path: 'enquiry',
          element: (
            <Suspense fallback={<PageLoading />}>
              <AdminEnquiry />
            </Suspense>
          ),
        },
        // { path: 'products', element: <ProductsPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: '*',
      element: (
        <Suspense fallback={<PageLoading />}>
          <Page404 />
        </Suspense>
      ),
    },
  ]);
  return routes;
}
