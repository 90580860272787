import { useEffect, useState, startTransition } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { authService } from '../services/authService';
import { setAdminUser } from '../features/userSlice';
import { routerPath } from '../config/constants';

const getToken = () => {
  const user = authService.user();
  if (user) {
    return user?.token || '';
  }
  return '';
};

export const AdminAxiosInstance = axios.create({
  baseURL: routerPath.baseApi,
});

export const AdminAxiosProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const adminUser = useSelector(selectAdminUser);
  const token = getToken();

  // Set Authorization header for all requests
  AdminAxiosInstance.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  useEffect(() => {
    AdminAxiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          setOpen(true);
          setMsg('Your session has expired. Please login again.');
        }
        return Promise.reject(error);
      }
    );
  }, []);

  const handleLogin = () => {
    // Clear user data from redux store
    startTransition(() => {
      // This state update will be delayed until after the next frame
      dispatch(setAdminUser(null));
      // Clear user data from local storage
      authService.removeUser();
      // Navigate to login page
      navigate('/auth');
    });
   
  };

  return (
    <>
      {props.children}
      <Dialog open={open}>
        <DialogTitle>{msg}</DialogTitle>
        <DialogActions>
          <Button onClick={handleLogin}>Continue with Login</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AdminAxiosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
