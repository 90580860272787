import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { routerPath } from '../../config/constants';
import { selectAdminUser, setAddAdminNotifications } from '../../features/userSlice';



// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));


let connection;


const connectSignalR = (userId) => {
  const url = `${routerPath.baseChatUrl}auth?userId=${userId}`;
  const newConnection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();
  try {
    newConnection
      .start()
      .then(() => {
        console.log('Admin Connection started!');
      })
      .catch(console.error);
  } catch (err) {
    console.log('Error while establishing connection :(', err);
  }
  connection = newConnection;
  return newConnection;
}

export const SendNotificationForUser = (data) => {
  if(!connection) {
    console.error('SignalR connection is not initialized.');
    return;
  }
  const productId = parseInt(data.productId, 10);
  connection.invoke(
    'SentPrivateNotificationForUser',
    data.userId,
    data.adminUserId,
    productId,
    data.notifyType /*  Notification Type Example Product created */,
    data.title /* Title of the Notification */,
    data.notificationDetail
  ).catch((error)=> {
    console.error(error);
  }); 
}
// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const auth = useSelector(selectAdminUser);
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth?.id) {
      if (!connection) {
        connectSignalR(auth?.id);
      }
      connection.on('ReceiveNotificationForAdmin', (senderId, NotificationValue) => {
        dispatch(
          setAddAdminNotifications({
            id: NotificationValue.id,
            title: NotificationValue.title,
            description: NotificationValue.description,
            avatar: NotificationValue.avatar,
            type: NotificationValue.type,
            createdAt: NotificationValue.createdAt,
            isUnRead: NotificationValue.isUnRead,
          })
        );
      });
    }
    return () => {
      if (connection) {
        connection.off('ReceiveNotificationForAdmin');
      }
    };
  }, [auth?.id]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
