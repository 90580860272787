import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// @mui
import { Box, Collapse, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';


// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

const SubNavItem = ({ item }) => {
  const { title, path, icon } = item;

  return (
    <StyledNavItem component={RouterLink} to={path}>
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
};

const NavItem = ({ item }) => {
  const { title, path, icon, subNav } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const renderBase = (path, title, icon) => (
    <StyledNavItem onClick={subNav && handleClick}>
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {subNav && (open ? <ExpandLess /> : <ExpandMore />)}
    </StyledNavItem>
  );

  if (subNav) {
    return (
      <>
        {renderBase(null, title, icon)}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {subNav.map((subNavItem) => (
              <SubNavItem key={subNavItem.title} item={subNavItem} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <StyledNavItem component={RouterLink} to={path}>
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
};

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};


SubNavItem.propTypes = {
  item: PropTypes.object,
};
