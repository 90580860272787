import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Router from './routes';

import ThemeProvider from './theme';

import ScrollToTop from './components/scroll-to-top';
import { SelectSnackBar, closeSnackBar } from './features/userSlice';

const vertical = 'bottom';
const horizontal = 'center';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function App() {
  const dispatch = useDispatch();
  const snackbar = useSelector(SelectSnackBar);

  const [snackBarOption, setSnackBarOption] = useState({
    open: null,
    message: null,
    severity: null,
  });

  const setSnackOption = () => {
    if (snackbar) {
      setSnackBarOption({
        ...snackBarOption,
        open: snackbar.open,
        message: snackbar.message,
        severity: snackbar.severity,
      });
    }
  };

  useEffect(()=>{
    setSnackOption();
  },[snackbar])

  return (
    <>
      <ThemeProvider>
        <ScrollToTop />
        <Router />
      </ThemeProvider>

      {snackBarOption.message && snackBarOption.open ? (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackBarOption.open}
            autoHideDuration={4000}
            key={vertical + horizontal}
            onClose={() => {
              dispatch(closeSnackBar());
            }}
          >
            <Alert
              onClose={() => {
                dispatch(closeSnackBar());
              }}
              severity={snackBarOption.severity}
              sx={{ width: '100%' }}
            >
              {snackBarOption.message}
            </Alert>
          </Snackbar>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
