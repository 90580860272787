import { PropTypes } from 'prop-types';
import {memo} from 'react';
import { Box, CircularProgress, Grid, Skeleton, TableCell, TableRow } from '@mui/material';

export const PageLoading = () => (
  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '10rem' }}>
    <CircularProgress color="primary" />
  </div>
);


export const SkeletonProviderTables = memo(({ columns = 1, visible = true }) => {
  const skeletons = Array.from(Array(columns).keys());

  if (!visible) {
    return null;
  }

  return (
    <TableRow>
      {skeletons.map((item) => (
        <TableCell key={item} align={'center'}>
          <Skeleton />
        </TableCell>
      ))}
    </TableRow>
  );
});

SkeletonProviderTables.propTypes = {
  columns: PropTypes.any,
  visible: PropTypes.bool,
};


export const ProductLoading = () => (
  <>
    <Grid container spacing={3}>
      <Grid item xs={6} sm={6} md={3}>
        <Box>
          <Skeleton variant="rectangular" width="100%" height="100%" />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
      </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={2} sm={4} md={4} sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
        <Box sx={{ margin: '1em' }}>
          <Skeleton variant="rectangular" width={250} height={150} />
          <Skeleton width="60%" />
          <Skeleton width="60%" />
        </Box>
      </Grid>
    </Grid>
  </>
);

export const HomePageCatLoading = () => (
  <>
    <Grid container>
      <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
        <Skeleton variant="circular" width={150} height={150} sx={{ margin: '1em' }} />
        <Skeleton variant="circular" width={150} height={150} sx={{ margin: '1em' }} />
        <Skeleton variant="circular" width={150} height={150} sx={{ margin: '1em' }} />
        <Skeleton variant="circular" width={150} height={150} sx={{ margin: '1em' }} />
        <Skeleton variant="circular" width={150} height={150} sx={{ margin: '1em' }} />
        <Skeleton variant="circular" width={150} height={150} sx={{ margin: '1em' }} />
      </Grid>
    </Grid>
  </>
);

export const HomeSliderLoading = () => (
  <>
    <Box>
      <Skeleton width={1100} height={400} />
    </Box>
  </>
);
