import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// const navConfig = [
//   {
//     title: 'dashboard',
//     path: 'dashboard',
//     icon: icon('ic_analytics'),
//   },
//   // {
//   //   title: 'user',
//   //   path: 'user',
//   //   icon: icon('ic_user'),
//   //   sub:[]
//   // },
//   {
//     title: 'Admin',
//     path: 'admin',
//     icon: icon('ic_cart'),
//     sub: [
//       {
//         id: 'adminUser',
//         title: 'Admin User',
//         type: 'item',
//         path: '/pages/login/login3',
//         target: true,
//       },
//       {
//         id: 'adminRole',
//         title: 'Admin Role',
//         type: 'item',
//         path: '/pages/register/register3',
//         target: true,
//       },
//     ],
//   },
//   {
//     id: 'adds',
//     title: 'Adds',
//     path: 'adds',
//     sub: [
//       {
//         id: 'add',
//         title: 'Adds',
//         type: 'item',
//         path: 'adds',
//         icon: AddPhotoAlternateIcon,
//         breadcrumbs: false,
//       },
//     ],
//   },
//   {
//     id: 'logs',
//     title: 'Logs',
//     path: 'logs',
//     sub: [
//       {
//         id: 'log',
//         title: 'Logs',
//         type: 'item',
//         path: 'logs',
//         icon: WebStoriesIcon,
//         breadcrumbs: false,
//       },
//     ],
//   },
//   {
//     id: 'product',
//     title: 'Product',
//     path: 'product',
//     sub: [
//       {
//         id: 'product',
//         title: 'Product',
//         type: 'item',
//         path: 'product',
//         icon: ProductionQuantityLimitsIcon,
//         breadcrumbs: false,
//       },
//     ],
//   },
//   {
//     id: 'users',
//     title: 'Users',
//     type: 'collapse',
//     icon: PeopleAltIcon,

//     sub: [
//       {
//         id: 'publicUsers',
//         title: 'Public Users',
//         type: 'item',
//         path: 'user',
//         target: true,
//       },
//       {
//         id: 'profileUpgrade',
//         title: 'Profile Upgrade',
//         type: 'item',
//         path: 'profileUpgrade',
//         target: true,
//       },
//     ],
//   },
// ];



const navConfig = [
  {
    title: 'dashboard',
    path: 'dashboard',
    icon: icon('ic_analytics'),
  },
  {
    title: 'User',
    path: '/user',
    icon: <PeopleAltIcon />,
    subNav: [
      {
        title: 'Public Users',
        path: 'user',
        icon: <PeopleAltIcon />,
      },
      {
        title: 'Profile Upgrade',
        path: 'profileUpgrade',
        icon: <PeopleAltIcon />,
      },
    ],
  },
  {
    title: 'Admin',
    path: 'admin',
    icon: <PeopleAltIcon />,
    subNav: [
      {
        title: 'Admin User',
        path: '/pages/login/login3',
        icon: <PeopleAltIcon />,
      },
      {
        title: 'Admin Role',
        path: '/pages/register/register3',
        icon: <PeopleAltIcon />,
      },
    ],
  },
  {
    title: 'Product',
    path: 'product',
    icon: <ProductionQuantityLimitsIcon />,
  },
  {
    title: 'category',
    path: 'category',
    icon: <ProductionQuantityLimitsIcon />,
  },
  // {
  //   title: 'Product Promotion',
  //   path: 'promote-product',
  //   icon: <PeopleAltIcon />,
  // },
  {
    title: 'Adds',
    path: 'adds',
    icon: <AddPhotoAlternateIcon />,
  },
  {
    title: 'Logs',
    path: 'logs',
    icon: <WebStoriesIcon />,
  },
  {
    title: 'Slider',
    path: 'slider',
    icon: <WebStoriesIcon />,
  },
  {
    title: 'Enquiry',
    path: 'enquiry',
    icon: <WebStoriesIcon />,
  },
];

export default navConfig;
