import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const account = {
  displayName: faker.name.fullName(),
  email: 'demo@minimals.cc',
  photoURL: faker.image.avatar(),
};

export default account;
